<template src="./VoucherReport.html"></template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import ManageRecipientsTable from "../../../clubDetails/allVoucher/manageRecipientsTableForVoucher/ManageRecipientsTable.vue";
import VoucherReportTable from "./voucherReportTable/VoucherReportTable.vue";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "../../../common/AppLoader/AppLoader";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { reportGen } from "@/services/clubDetails/voucherReports.service";
export default {
  components: {
    kbutton: Button,
    ManageRecipientsTable,
    VoucherReportTable,
    AppSnackBar,
    "app-loader": AppLoader,
    Breadcrumb,
  },
  data() {
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      ifUserIsReportViewer: false,
      isLoading: true,
      clubId: this.$route.params.id,
      voucherCampaignId: +this.$route.query.voucher_campaign_id || 0,
      isFromManagaeRecipients:
        this.$route.query.from_manage_recipients || false,
      isReportPostLoading: false,
      title: "Displaying history of transactions of all voucher programs.",
    };
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    if (this.voucherCampaignId) {
      this.title =
        "Displaying history of transactions of current voucher programs.";
    } else {
      this.title =
        "Displaying history of transactions of all voucher programs.";
    }

    this.getRecipient();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  created() {
    const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
    if (clubInfo?.roleId === 2) {
      this.ifUserIsReportViewer = true;
    } else {
      this.ifUserIsReportViewer = false;
    }
  },
  unmounted() {
    this.$store.commit("SET_REPORTS", []);
  },
  computed: {
    reportsData() {
      return this.$store.state.voucherReports.setReportsData;
    },
    isSearchingOrFilterig() {
      return this.$store.state.voucherReports.searchQuery;
    },
    isShowMsg() {
      return this.$store.state.voucherReports.showMsg;
    },
    showMsg() {
      return this.$store.state.voucherReports.status;
    },
    reportListLoading() {
      return this.$store.state.voucherReports.loading;
    },
    isSearching() {
      return this.$store.state.voucherReports.searchQuery;
    },
    isAdvanceFiltering() {
      return this.$store.state.voucherReports.isAdvanceFiltering;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    navigateToVoucherHomepage() {
      this.$router.push({
        name: "AllVoucher",
      });
    },
    RenderData() {
      this.isReportPostLoading = true;
      let payload = {
        clubId: this.clubId,
        VoucherCampaignId: this.voucherCampaignId,
        totalCount: 500,
      };

      reportGen(payload)
        .then((response) => {
          this.isReportPostLoading = false;
          this.getRecipient();
        })
        .catch((err) => {
          this.isReportPostLoading = false;
        });
    },
    navigateToManageRecipients() {
      this.$router.push({
        name: "VoucherManageRecipients",
        query: {
          voucherCampaignId: +this.$route.query.voucher_campaign_id,
          club_name: this.$route.query.club_name,
          type: +this.$route.query.type,
        },
        params: {
          voucherCampaignId: +this.$route.query.voucher_campaign_id,
        },
      });
    },

    cancelButtonHandler() {
      if (this.$route.query.club_name) {
        if (this.ifUserIsReportViewer) {
          this.navigateToVoucherHomepage();
        } else {
          this.navigateToManageRecipients();
        }
      } else {
        this.navigateToVoucherHomepage();
      }
    },
    getRecipient() {
      let payload = {
        voucherCampaignId: this.voucherCampaignId,
        clubId: this.clubId,
        offset: 0,
        limit: 100,
      };
      this.$store.dispatch("getVoucherReports", payload);
    },
  },
};
</script>

<style></style>
