<template src="./VoucherReportTable.html"></template>
<style lang="scss">
@import "./VoucherReportTable.scss";
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Input, RadioButton } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import moment from "moment";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import vClickOutside from "click-outside-vue3";

export default {
  name: "ManageRecipientsTable",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    Popup: Popup,
    datePicker: DatePicker,
    AppSnackBar,
    radioButton: RadioButton,
    dropdownlist: DropDownList,
    numericTextBox: NumericTextBox,
    radiogroup: RadioGroup,
  },

  data() {
    return {
      dataItems: [
        {
          label: "On",
          value: 1,
        },
        {
          label: "Before",
          value: 2,
        },
        {
          label: "After",
          value: 3,
        },
        {
          label: "Between",
          value: 4,
        },
      ],
      amountType: [
        { id: 1, text: "=", value: 1 },
        { id: 2, text: ">=", value: 2 },
        { id: 3, text: "<=", value: 3 },
        { id: 4, text: ">", value: 4 },
        { id: 5, text: "<", value: 5 },
      ],
      sortBy: "",
      sortDir: "",
      sort: [{ field: "recipientName", dir: "asc" }],
      amountTypeValue: { id: 1, text: "=", value: 1 },
      redeemedAmount: "",
      redeemedBetweenStartDate: "",
      redeemedBetweenEndDate: "",
      advance_filter_redeemed_on_option: 1,
      redeemedOnOptions: {
        on: 1,
        before: 2,
        after: 3,
        between: 4,
      },
      clubId: this.$route.params.id,
      voucherCampaignId: +this.$route.query.voucher_campaign_id || 0,
      redeemedDate: "",
      searchWord: "",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      timer: null,
      advanceFilterShow: false,
      isClearAdvanceFilterClicked: false,
      isAdvanceFilteringExist: false,
      advanceFilterPayload: {},
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          field: "recipientName",
          title: "Name",
        },
        {
          field: "voucherCode",
          title: "Voucher Code",
          cell: "voucherCodeCell",
        },
        {
          field: "redeemedOn",
          title: "Redeemed On",
          cell: "redeemedOnCell",
        },
        {
          field: "redeemedAmount",
          title: "Redeemed Amount",
          cell: "redeemedAmountCell",
        },
        {
          field: "voucherAmount",
          title: "Voucher Amount",
          cell: "voucherAmountCell",
        },
        {
          field: "salesAmount",
          title: "Sales Amount",
          cell: "usedAmountCell",
        },
        {
          field: "orderReference",
          title: "Order Reference",
        },
        {
          field: "orderSource",
          title: "Order Source",
        },
      ];
    },
    reportsData() {
      return this.$store.state.voucherReports.setReportsData;
    },
    total() {
      return this.reportsData ? this.reportsData.size : 0;
    },
    take() {
      return this.reportsData ? this.reportsData.limit : 0;
    },
    skip() {
      return this.reportsData ? this.reportsData.offset : 0;
    },
    isSearching() {
      return this.$store.state.members.searchQuery;
    },
    showSuccessMsg() {
      return this.$store.state.voucherReports.successStatus;
    },
    showErrorMsg() {
      return this.$store.state.voucherReports.errorStatus;
    },
    isShowSuccessMsg() {
      return this.$store.state.voucherReports.showSuccessMsg;
    },
    isShowErrorMsg() {
      return this.$store.state.voucherReports.showErrorMsg;
    },
  },
  methods: {
    sortChangeHandler: function (e) {
      this.sort = e.sort;

      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      let payload = this.generatePayload();

      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }

      this.$store.dispatch("getVoucherReports", payload);
    },
    getDate(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "01/05/2023";
      }
    },
    updateRedeemedDate(event) {
      this.redeemedDate = event.target.value;
    },
    updateRedeemedBetweenStartDate(event) {
      this.redeemedBetweenStartDate = event.target.value;
    },
    updateRedeemedBetweenEndDate(event) {
      this.redeemedBetweenEndDate = event.target.value;
    },
    handleAmountTypeChanges(e) {
      this.amountTypeValue = e.target.value;
    },
    handleChange(e) {
      this.redeemedDate = "";
      this.advance_filter_redeemed_on_option = e.value;
    },
    getAmount(amount) {
      if (amount != null) {
        return `$${amount.toFixed(2)}`;
      } else {
        return "$0.00";
      }
    },
    onSearch(e) {
      this.searchWord = e.value;
      const payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        limit: this.take,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getVoucherReports", payload);
      }, 800);
    },
    handleAdvanceFilter() {
      const payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        redeemedDateStart: this.redeemedDateStart,
        redeemedDateEnd: this.redeemedDateEnd,
      };
      this.$store.dispatch("getVoucherReports", payload);
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        clubId: +this.clubId,
        limit: event.data.take,
        offset: event.data.skip,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
      };

      if (this.isAdvanceFilteringExist) {
        this.advanceFilterPayload.limit = event.data.take;
        this.advanceFilterPayload.offset = event.data.skip;
        payload = {
          ...payload,
          ...this.advanceFilterPayload,
        };
      }
      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }

      this.$store.dispatch("getVoucherReports", payload);
    },
    closeDataUnavailableUserModal() {
      this.isCkUserDataAvailableModalOpen = false;
    },
    getRecipient() {
      const payload = {
        clubId: this.clubId,
        voucherCampaignId: this.voucherCampaignId,
      };
      this.$store.dispatch("getVoucherReports", payload);
    },
    exportReport() {
      let payload = this.generatePayload();
      payload = {
        ...payload,
        ...(this.$route.query.campaign_name && {
          name: this.$route.query.campaign_name,
        }),
        ...(this.$route.query.clubName && {
          name: this.$route.query.clubName,
        }),
      };
      this.$store.dispatch("voucherReportExport", payload);
    },
    advanceFilterShowHandler() {
      this.advanceFilterShow = !this.advanceFilterShow;
    },
    clearAdvanceFilterField() {
      this.amountTypeValue = { id: 1, text: "=", value: 1 };
      this.advance_filter_redeemed_on_option = 1;
      this.isClearAdvanceFilterClicked = true;
      this.redeemedAmount = "";
      this.redeemedDate = "";
      this.redeemedBetweenStartDate = "";
      this.redeemedBetweenEndDate = "";
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    advanceFilterDataValidation() {
      // if (
      //   !this.redeemedDate &&
      //   !this.redeemedBetweenStartDate &&
      //   !this.redeemedBetweenEndDate &&
      //   !this.redeemedAmount
      // ) {
      //   this.showNotification(
      //     "Please input filter data to proceed with advance filter.",
      //     "warning"
      //   );
      //   return false;
      // }
      if (this.redeemedBetweenStartDate && this.redeemedBetweenEndDate) {
        if (this.redeemedBetweenStartDate > this.redeemedBetweenEndDate) {
          this.showNotification("Please input valid time range.", "warning");
          return false;
        }
      }
      return true;
    },
    closeAdvanceFilter() {
      this.advanceFilterShow = false;
    },
    generatePayload() {
      let payload = {
        clubId: +this.clubId,
        voucherCampaignId: this.voucherCampaignId,
        searchText: this.searchWord,
        limit: this.take,
        offset: this.skip,
      };

      if (this.redeemedAmount === 0 || this.redeemedAmount > 0) {
        payload = {
          ...payload,
          redeemAmountFilterType: this.amountTypeValue.value,
          redeemAmountFilterValue: this.redeemedAmount,
        };
      }

      if (
        this.advance_filter_redeemed_on_option === 4 &&
        this.redeemedBetweenStartDate &&
        this.redeemedBetweenEndDate
      ) {
        payload = {
          ...payload,
          redeemDateFilterType: this.advance_filter_redeemed_on_option,
          redeemDateFilterStartDate: this.redeemedBetweenStartDate,
          redeemDateFilterEndDate: this.redeemedBetweenEndDate,
        };
      }

      if (this.redeemedDate) {
        payload = {
          ...payload,
          redeemDateFilterType: this.advance_filter_redeemed_on_option,
          redeemDateFilterStartDate: this.redeemedDate,
        };
      }
      return payload;
    },
    applyAdvanceFiltering() {
      if (this.advanceFilterDataValidation()) {
        this.isAdvanceFilteringExist = true;

        if (this.isClearAdvanceFilterClicked) {
          this.isClearAdvanceFilterClicked = false;
          this.isAdvanceFilteringExist = false;
        }

        this.advanceFilterShow = false;

        const payload = this.generatePayload();
        this.advanceFilterPayload = payload;

        this.$store.dispatch("getVoucherReports", payload);
      }
    },
  },
};
</script>
