<!-- <h1>{{template_id}}</h1> -->
<AppSnackBar
  :message="showSuccessMsg.Message"
  :notificationType="showSuccessMsg.type"
  v-if="isShowSuccessMsg"
>
</AppSnackBar>
<AppSnackBar
  :message="showErrorMsg.Message"
  :notificationType="showErrorMsg.type"
  v-if="isShowErrorMsg"
></AppSnackBar>
<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>

<div class="dashboard-right-inner">
  <div class="dashboard-content">
    <div>
      <Grid
        ref="grid"
        :class="'max-height-100-251 min-h-500-h-610 voucher-report-table-wrap sorting-btn-th'"
        :data-items="reportsData.items"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        :columns="columns"
        @datastatechange="dataStateChange"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
      >
        <template v-slot:emailStatusCell="{props}">
          <td>
            <span
              v-if="props.dataItem.emailStatus == 'Sent'"
              class="k-icon k-i-check color-success"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 'Not Sent'"
              class="k-icon k-i-close color-danger"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 'Opened'"
              class="k-icon k-i-close color-danger"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 'Delivered'"
              class="k-icon k-i-close color-danger"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 'Not Delivered'"
              class="k-icon k-i-close color-danger"
            ></span>
            <span
              v-if="props.dataItem.emailStatus == 'Clicked'"
              class="k-icon k-i-close color-danger"
            ></span>
            <span class="ps-2">{{props.dataItem.emailStatus}}</span>
          </td>
        </template>

        <template v-slot:expiryDateCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{getDate(props.dataItem.expiryDate)}}</span>
            </div>
          </td>
        </template>

        <template v-slot:voucherCodeCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{props.dataItem.voucherCode}}</span>
            </div>
          </td>
        </template>

        <template v-slot:usedAmountCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{getAmount(props.dataItem.salesAmount)}}</span>
            </div>
          </td>
        </template>

        <template v-slot:redeemedOnCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{getDate(props.dataItem.redeemedOn)}}</span>
            </div>
          </td>
        </template>

        <template v-slot:redeemedAmountCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{getAmount(props.dataItem.redeemedAmount)}}</span>
            </div>
          </td>
        </template>

        <template v-slot:voucherAmountCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{getAmount(props.dataItem.voucherAmount)}}</span>
            </div>
          </td>
        </template>

        <template v-slot:remainingAmountCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span>{{getAmount(props.dataItem.remainingAmount)}}</span>
            </div>
          </td>
        </template>
        <toolbar>
          <div class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input
                :class="'mb-0 width-220 w-100-vsm'"
                placeholder="Search"
                :value="searchWord"
                @input="onSearch"
              ></k-input>
            </span>
          </div>

          <div class="pe-3 pr-0-vsm w-100-vsm">
            <kbutton
              :fill-mode="'flat'"
              :theme-color="'primary'"
              :icon="'filter'"
              @click="advanceFilterShowHandler"
              ref="advanceFilterRef"
              :class="'w-100-vsm'"
            >
              Advanced Filtering
            </kbutton>
            <Popup
              :anchor="'advanceFilterRef'"
              :show="advanceFilterShow"
              :popup-class="'advance-filter-popup'"
            >
              <div class="advance-filter-popup-wrap">
                <div
                  class="head d-flex justify-content-between align-items-center p-16"
                >
                  <h5 class="font-16 m-0">Advanced Filtering</h5>
                  <kbutton
                    :fill-mode="'flat'"
                    @click="clearAdvanceFilterField"
                    :icon="'close'"
                    >Clear</kbutton
                  >
                </div>
                <div class="p-24">
                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <label class="" for="">Redeemed On</label>
                    </div>
                    <div class="col-md-12">
                      <div class="d-flex justify-content-between mb-3">
                        <radiogroup
                          :default-value="dataItems[2].value"
                          :data-items="dataItems"
                          :layout="'horizontal'"
                          :value="advance_filter_redeemed_on_option"
                          @change="handleChange"
                        />
                      </div>
                    </div>

                    <div
                      v-if="advance_filter_redeemed_on_option != 4"
                      class="col-md-12"
                    >
                      <div>
                        <datePicker
                          :format="'dd/MMM/yyyy'"
                          :default-value="new Date()"
                          @change="updateRedeemedDate"
                          :value="redeemedDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                    <div
                      v-if="advance_filter_redeemed_on_option===4"
                      class="col-md-6"
                    >
                      <div>
                        <datePicker
                          :format="'dd/MMM/yyyy'"
                          :default-value="new Date()"
                          @change="updateRedeemedBetweenStartDate"
                          :value="redeemedBetweenStartDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                    <div
                      v-if="advance_filter_redeemed_on_option===4"
                      class="col-md-6"
                    >
                      <div>
                        <datePicker
                          :format="'dd/MMM/yyyy'"
                          :default-value="new Date()"
                          @change="updateRedeemedBetweenEndDate"
                          :value="redeemedBetweenEndDate"
                        >
                        </datePicker>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <label class="" for="">Redeemed Amount</label>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div
                          class="col-sm-6 col-7 pe-1 pe-md-2 mb-2 mb-sm-0 d-flex justify-content-between align-content-center"
                        >
                          <label class="mb-0 h-38 l-height-38">Amount</label>
                          <dropdownlist
                            :style="{ width: '80px' }"
                            :data-items="amountType"
                            :text-field="'text'"
                            :class="'w-60-vsm'"
                            :data-item-key="'id'"
                            :value="amountTypeValue"
                            @change="handleAmountTypeChanges"
                          >
                          </dropdownlist>
                        </div>
                        <div class="col-sm-6 col-5">
                          <numericTextBox v-model="redeemedAmount" :min="0">
                          </numericTextBox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="me-1 w-50-p">
                      <kbutton @click="closeAdvanceFilter" :class="'w-100'">
                        Cancel
                      </kbutton>
                    </div>
                    <div class="ms-1 w-50-p">
                      <kbutton
                        @click="applyAdvanceFiltering"
                        :theme-color="'primary'"
                        :class="'w-100'"
                      >
                        Apply
                      </kbutton>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          </div>

          <div class="ms-auto ml-unset-767 w-100-vsm">
            <div class="d-flex w-100-vsm">
              <kbutton
                @click="exportReport"
                :class="'me-3 mr-0-vsm btn-bg-white border-black w-100-vsm'"
                :icon="'download'"
                >Export</kbutton
              >
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>
</div>
